import moment from 'moment-timezone';
import { browserHistory } from 'react-router';
import PubSub from 'pubsub-js';
import API, { API_V2 } from '../componentes/restservice/api.instance';
import APIDelete from '../componentes/restservice/apiDelete.instance';
import Config from '../componentes/restservice/Utils';
import errorHandler from '../componentes/utils/errorHandler';
import teamSettings from '../componentes/utils/TeamsSettings/generalSettings';
import { teamsActivities } from '../componentes/utils/TeamsActivities/teamsActivities';
import { teamsAppDiscovery } from '../componentes/utils/TeamsActivities/teamsAppDiscovery';
import { style } from '../style';
import Axios from 'axios';

const hasExternalActivities = ['Vedacit', 'mestreconstrutor', 'Ecom'].includes(
  style.teamName.name
);

const hasExternalRegister = style.variables.autoRegister;

export default class UserApi {
  static insertNewUser(store) {
    return (dispatch) => {
      API.post('users/', Config.headers())
        .then(
          function(response) {
            var tags = this.parseObject(response.data);
            store.dispatch({ type: 'GETTAG', tags });
            store.dispatch({ type: 'NO_SUBMITTING' });
          }.bind(this)
        )
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static getUserRoles(id) {
    return async (dispatch) => {
      const { data } = await API.get(`users/${id}`, Config.headers());

      dispatch({
        type: 'GET_USER_ROLES',
        userRoles: data.roles[0].name
      });
    };
  }

  static restoreUser(id) {
    return (dispatch) => {
      API.put('users/' + id + '/restore', {}, Config.headers())
        .then(function(response) {
          browserHistory.push('/user/list');
          dispatch({
            type: 'CLOSE_ALERT',
            alert: {
              visible: false
            }
          });
        })
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static closeDialogGift() {
    return (dispatch) => {
      dispatch({ type: 'SHOW_MODAL_BIRTHDAY' });
      dispatch({ type: 'CLOSE_DIALOG_SCORE', alert: false });
    };
  }

  static updateUserScoreByGift(users, gift) {
    return (dispatch) => {
      dispatch({ type: 'PONTUATION_USERS_IS_LOADING' });

      var formData = new FormData();

      formData.append('justification', gift.justification);
      formData.append('score', gift.score);
      users.map(function(user) {
        formData.append('gift_user[]', user);
      });
      API.patch(
        'users/' + localStorage.getItem('Userid'),
        formData,
        Config.headers()
      )
        .then(
          function(response) {
            dispatch(this.getUserList());
            dispatch({ type: 'PONTUATION_USERS_NOT_IS_LOADING' });
            dispatch({ type: 'HIDE_MODAL_BIRTHDAY' });
          }.bind(this)
        )
        .catch(function(err) {
          dispatch({ type: 'SHOW_MODAL_BIRTHDAY' });
          dispatch({ type: 'CLOSE_DIALOG_SCORE', alert: false });
          dispatch({ type: 'PONTUATION_USERS_NOT_IS_LOADING' });
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
          PubSub.publish('clear-list-marked', true);
        });
    };
  }

  static updateUserScoreByGiftByFilter(filter, gift) {
    return (dispatch) => {
      dispatch({ type: 'PONTUATION_USERS_IS_LOADING' });

      var formData = new FormData();
      formData.append('filter', filter.filter);
      formData.append('justification', gift.justification);
      formData.append('score', gift.score);
      switch (filter.filter) {
        case 'sex':
          formData.append('value_filter', filter.sex);
          break;
        case 'sector':
          formData.append('value_filter', filter.sector);
          break;
        case 'birthday':
          formData.append('value_filter', filter.month_birthday);
          break;
      }

      API.patch(
        'users/' + localStorage.getItem('Userid') + '?&gift_user',
        formData,
        Config.headers()
      )
        .then(
          function(response) {
            dispatch(this.getUserList());
            dispatch({ type: 'HIDE_MODAL_BIRTHDAY' });
            dispatch({ type: 'PONTUATION_USERS_NOT_IS_LOADING' });
          }.bind(this)
        )
        .catch(function(err) {
          dispatch({ type: 'SHOW_MODAL_BIRTHDAY' });
          dispatch({ type: 'CLOSE_DIALOG_SCORE', alert: false });
          dispatch({ type: 'PONTUATION_USERS_NOT_IS_LOADING' });
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
          PubSub.publish('clear-list-marked', true);
        });
    };
  }

  static scorePointsToUsersViaSpreadsheet(file, score, justification, email) {
    return (dispatch) => {
      dispatch({ type: 'PONTUATION_USERS_IS_LOADING' });

      var formData = new FormData();
      formData.append('spreadsheet', file);
      formData.append('score', score);
      formData.append('justification', justification);
      formData.append('responsible_email', email);

      API_V2.post('score_by_spreadsheets', formData, Config.headers())
        .then(
          function(response) {
            dispatch({
              type: 'PONTUATION_USERS_SUCCESS',
              apiMessage: response.data.mesage
            });
            dispatch({ type: 'PONTUATION_USERS_NOT_IS_LOADING' });
          }.bind(this)
        )
        .catch(function(err) {
          dispatch({ type: 'PONTUATION_USERS_NOT_IS_LOADING' });
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static tagAssignmentToUsersViaSpreadsheet(file, email, tags_user) {
    return (dispatch) => {
      dispatch({ type: 'TAG_ASSIGNMENT_USERS_IS_LOADING' });

      var formData = new FormData();
      formData.append('spreadsheet', file);
      formData.append('responsible_email', email);
      tags_user.map((tag) => formData.append('tags[]', tag.value));

      API_V2.post('attach_tags_by_spreadsheets', formData, Config.headers())
        .then(
          function(response) {
            dispatch({
              type: 'TAG_ASSIGNMENT_USERS_SUCCESS',
              apiMessage: response.data.mesage
            });
            dispatch({ type: 'TAG_ASSIGNMENT_USERS_NOT_IS_LOADING' });
          }.bind(this)
        )
        .catch(function(err) {
          dispatch({ type: 'TAG_ASSIGNMENT_USERS_NOT_IS_LOADING' });
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static sendEmailNewPassword(values) {
    return (dispatch) => {
      API.post('users/reset_password', { email: values.email_user })
        .then(function(response) {
          browserHistory.push('/');
          dispatch({ type: 'NO_SUBMITTING' });
        })
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static rePassword(values) {
    return (dispatch) => {
      if (
        values.password_user_new !== '' &&
        values.password_user_new !== undefined
      ) {
        var formData = new FormData();
        formData.append('password', values.password_user_new);
        API.put('users/' + values.id + '/password', formData, Config.headers())
          .then(function(response) {
            browserHistory.push('/user/list');
            dispatch({ type: 'NO_SUBMITTING' });
          })
          .catch(function(err) {
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          });
      } else {
        dispatch({
          type: 'SHOW_ERRO_ALERT',
          alert: {
            visible: true,
            message: 'Informe a nova senha'
          }
        });
      }
    };
  }

  static getUserList() {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      API.get('users/?page=1', Config.headers())
        .then(
          function(response) {
            var user_list = this.parseUserList(response.data);
            dispatch({
              type: 'GET_USER_LIST',
              user_list
            });
            dispatch({
              type: 'GET_PAGE',
              page: 1
            });
            dispatch({ type: 'NO_SUBMITTING' });
            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
          }.bind(this)
        )
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static getUserListName() {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      API.get('users/?page=1&per_page=1&publishers=true', Config.headers())
        .then(
          function(response) {
            const per_page = response.data[0].size[0];
            API.get(
              `users/?page=1&per_page=${per_page}&publishers=true`,
              Config.headers()
            ).then(
              function(respName) {
                const userListName = this.parseUserListName(respName.data);
                dispatch({
                  type: 'GET_USER_LIST_NAME',
                  userListName
                });
              }.bind(this)
            );
            dispatch({ type: 'NO_SUBMITTING' });
            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
          }.bind(this)
        )
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static getAuthors() {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      API_V2.get('users/autors?page=1&per_page=99999', Config.headers())
        .then(
          function(response) {
            const userListName = this.parseUserListName(response.data.users);
            dispatch({
              type: 'GET_USER_LIST_NAME',
              userListName
            });
            dispatch({ type: 'NO_SUBMITTING' });
            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
          }.bind(this)
        )
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static getUserName(param) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      API.get(`users?uid_or_name=${param}`, Config.headers())
        .then((response) => {
          let user_list = this.parseUserList(response.data);
          let page = 1;
          dispatch(
            {
              type: 'GET_USER_LIST',
              user_list
            },
            {
              type: 'GET_PAGE',
              page: page
            }
          );
          dispatch({ type: 'NO_SUBMITTING' });
          dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
        })
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static getUserPage(page = 1, order = '') {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      API.get(`users/?page=${page}&score=${order}`, Config.headers())
        .then((response) => {
          let user_list = this.parseUserList(response.data);

          dispatch({ type: 'GET_USER_LIST', user_list });
          dispatch({ type: 'GET_PAGE', page });
          dispatch({ type: 'NO_SUBMITTING' });
          dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
        })
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static deleteUser(id) {
    return (dispatch) => {
      let API_CLIENT = API;

      if (style.teamName.name === 'youseed') {
        API_CLIENT = APIDelete;
      }

      API_CLIENT.delete('users/' + id, Config.headers())
        .then(
          function(response) {
            dispatch(this.getUserList());
            dispatch({
              type: 'CLOSE_ALERT',
              alert: {
                visible: false
              }
            });
            dispatch({ type: 'NO_SUBMITTING' });
          }.bind(this)
        )
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static editUser(id) {
    return (dispatch) => {
      dispatch({ type: 'EDIT_USER_RESET' });
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      API.get('users/' + id, Config.headers())
        .then(
          function(response) {
            var user = this.parseUser(response.data);
            dispatch({ type: 'EDIT_USER', userEdit: user });
            dispatch({ type: 'NO_SUBMITTING' });
            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
            browserHistory.push('/user/edit');
          }.bind(this)
        )
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static patchUser(user_data, contacts, academyRole) {
    return async (dispatch) => {
      try {
        const user_parsed = this.formDataCreate(
          user_data,
          contacts,
          academyRole,
          true
        );
        await API.patch(`users/${user_data.id}`, user_parsed, Config.headers());

        dispatch({
          type: 'OPEN_SUCCESS',
          alert: {
            visible: true,
            message: 'Usuário foi atualizado com sucesso!'
          }
        });

        this.getUserList();
        dispatch({
          type: 'UPDATE_CITY',
          city: {}
        });
        dispatch({
          type: 'UPDATE_STATE',
          state: {}
        });
        browserHistory.push('/user/list');
        dispatch({ type: 'EDIT_USER_RESET' });

        dispatch({ type: 'NO_SUBMITTING' });
      } catch (error) {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      }
    };
  }

  static saveUser(user_data, contacts, academyRole) {
    return async (dispatch) => {
      try {
        const user_parsed = this.formDataCreate(
          user_data,
          contacts,
          academyRole
        );
        // const user_parsed = this.testDataCreate();
        await API.post('users?origin=admin', user_parsed, Config.headers());

        this.getUserList();
        dispatch({
          type: 'UPDATE_CITY',
          city: {}
        });
        dispatch({
          type: 'UPDATE_STATE',
          state: {}
        });
        browserHistory.push('/user/list');

        dispatch({ type: 'NO_SUBMITTING' });
      } catch (error) {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      }
    };
  }

  static logInUser({ email_user, password_user }, recaptchaSuccess) {
    return async (dispatch) => {
      try {
        if (!recaptchaSuccess) {
          dispatch({
            type: 'SHOW_ERRO_ALERT',
            alert: {
              visible: true,
              message: 'Clique em "Não sou um robô" para continuar.'
            }
          });
          dispatch({ type: 'NO_SUBMITTING' });
          setTimeout(() => {
            dispatch({
              type: 'CLOSE_ERRO_ALERT',
              alert: {
                visible: false
              }
            });
          }, 2000);
          return;
        }

        const response = await API.post('users/auth/sign_in', {
          username: email_user,
          password: password_user
        });

        localStorage.setItem(
          'tagspeaker',
          JSON.stringify(response.data.tagspeaker)
        );

        const isNormalUser = response.data.roles.find(
          (role) => role.name === 'normal_user'
        );
        if (isNormalUser) {
          dispatch({
            type: 'SHOW_ERRO_ALERT',
            alert: {
              visible: true,
              message: 'Usuário não tem permissão para acesso.'
            }
          });
          dispatch({ type: 'NO_SUBMITTING' });
          setTimeout(() => {
            dispatch({
              type: 'CLOSE_ERRO_ALERT',
              alert: {
                visible: false
              }
            });
          }, 2000);
          return;
        }

        const isGuest = response.data.roles.find(
          (role) => role.name === 'guest'
        );
        if (isGuest) {
          dispatch({
            type: 'SHOW_ERRO_ALERT',
            alert: {
              visible: true,
              message: 'Usuário não tem permissão para acesso.'
            }
          });
          dispatch({ type: 'NO_SUBMITTING' });
          setTimeout(() => {
            dispatch({
              type: 'CLOSE_ERRO_ALERT',
              alert: {
                visible: false
              }
            });
          }, 2000);
          return;
        }

        const isPJ = response.data.roles.find(
          (role) => role.name === 'legal_person'
        );
        if (isPJ) {
          dispatch({
            type: 'SHOW_ERRO_ALERT',
            alert: {
              visible: true,
              message: 'Usuário não tem permissão para acesso.'
            }
          });
          dispatch({ type: 'NO_SUBMITTING' });
          setTimeout(() => {
            dispatch({
              type: 'CLOSE_ERRO_ALERT',
              alert: {
                visible: false
              }
            });
          }, 2000);
          return;
        }

        const isAdmin = response.data.roles.find(
          (role) => role.name === 'admin'
        );

        const isStore = response.data.roles.find(
          (role) => role.name === 'store'
        );

        const isManager = response.data.roles.find(
          (role) => role.name === 'manager'
        );

        const isUserEditor = response.data.roles.find(
          (role) => role.name === 'editor'
        );

        if (isAdmin) {
          localStorage.setItem('ADMIN', true);
        }

        localStorage.setItem('token', response.headers['access-token']);
        localStorage.setItem('client', response.headers['client']);
        localStorage.setItem('uid', response.headers['uid']);
        localStorage.setItem(
          'resource-type',
          response.headers['resource-type']
        );
        localStorage.setItem('Userid', response.data.id);
        localStorage.setItem('UserName', response.data.name);
        localStorage.setItem('UserImage', response.data.image.url);
        localStorage.setItem('UserRoles', JSON.stringify(response.data.roles));

        dispatch({ type: 'CREATE_STORAGE', userStorage: response.data });

        if (isAdmin || isManager) {
          browserHistory.push('/home');
        } else if (isStore) {
          browserHistory.push('/store/products');
        } else if (isUserEditor) {
          browserHistory.push('/user/list');
        } else {
          browserHistory.push('/post/publication');
        }

        dispatch({ type: 'NO_SUBMITTING' });
      } catch (error) {
        dispatch({
          type: 'SHOW_ERRO_ALERT',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
        dispatch({ type: 'NO_SUBMITTING' });
        setTimeout(() => {
          dispatch({
            type: 'CLOSE_ERRO_ALERT',
            alert: {
              visible: false
            }
          });
        }, 2000);
      }
    };
  }

  static parseObject(arrays) {
    var arraysname = [];
    if (arrays.length > 0) {
      arrays.map(function(arraysindex, index) {
        return arraysname.push(arraysindex.name);
      });
    }
    return arraysname;
  }

  static parsepermissions(arrays) {
    var arrayspermissions = [];
    if (arrays.length > 0) {
      arrays.map(function(arraysindex, index) {
        switch (arraysindex) {
          case 'Administrador':
            arrayspermissions.push('admin');
            break;
        }
      });
    }
    return arrayspermissions;
  }

  static parseUser(user_object) {
    const { hasBrands } = teamSettings();

    function getSectorIdByName(name) {
      const discovery = teamsAppDiscovery[style.teamName.name].find(
        (item) => item.name_sector === name
      );
      return discovery ? discovery.id_sector : null;
    }

    const user_parsed = {};
    user_parsed.id = user_object.id;
    user_parsed.name_user = user_object.name;
    user_parsed.auto_declared = user_object.auto_declared;
    user_parsed.app_discovery = getSectorIdByName(user_object.app_discovery);
    user_parsed.cnpj = user_object.cnpj;
    if (user_object.datebirthday !== undefined) {
      user_parsed.birthday_user = moment
        .tz(user_object.datebirthday, 'Europe/Berlin')
        .format('DD/MM/YYYY');
    }
    user_parsed.email_user = user_object.email;
    user_parsed.permission_userArray = [];
    if (user_object.roles !== undefined) {
      this.parseRoles(user_object.roles).map(function(role, i) {
        return user_parsed.permission_userArray.push(role);
      });
    }
    user_parsed.permission_user = user_parsed.permission_userArray[0];
    user_parsed.sector_id = user_object.sector.id;
    user_parsed.score_user = user_object.score;

    user_parsed.contacts = user_object.contacts.map((contact) => ({
      id: contact.id,
      kind: contact.kind,
      contact: contact.contact
    }));

    if (user_object.contacts !== null) {
      user_object.contacts.forEach((contact) => {
        user_parsed[`contact_${contact.kind}`] = contact.contact;
      });
    }

    user_parsed.admitted_at = moment
      .tz(user_object.admitted_at, 'Europe/Berlin')
      .format('DD/MM/YYYY');

    user_parsed.tags_user = [];
    let genders = [
      'Mulher cis',
      'Mulher trans',
      'Homem cis',
      'Homem trans',
      'Não binário',
      'Prefere não se identificar'
    ];
    if (genders.includes(user_object.sex)) {
      user_parsed.sex_user = user_object.sex;
    } else {
      user_parsed.sex_user = 'Outros';
      user_parsed.custom_gender = user_object.sex;
    }

    user_object.tags.map(function(tag, i) {
      user_parsed.tags_user.push({ value: tag.name, label: '#' + tag.name });
    });

    if (user_parsed.permission_userArray.includes('Speaker')) {
      user_parsed.tags_user_speaker = [];
      user_object.tagspeaker.map(function(tag, i) {
        user_parsed.tags_user_speaker.push({
          value: tag.name,
          label: '#' + tag.name
        });
      });
      user_parsed.score_user_speaker = user_object.scorespeaker;
      user_parsed.publications_score = user_object.publications_score;
    }

    user_parsed.img = [
      {
        preview: user_object.image.url
      }
    ];

    if (hasBrands) {
      user_parsed.mainBrand = user_object.main_brand;
      user_parsed.brands = user_object.brands;
    }

    if (hasExternalRegister) {
      user_parsed.complement =
        user_object.address && user_object.address.complement
          ? user_object.address.complement
          : '';
      user_parsed.street =
        user_object.address && user_object.address.street
          ? user_object.address.street
          : '';
      user_parsed.number =
        user_object.address && user_object.address.number
          ? user_object.address.number
          : '';
      user_parsed.zip_code =
        user_object.address && user_object.address.zip_code
          ? user_object.address.zip_code
          : '';
      user_parsed.neighborhood =
        user_object.address && user_object.address.neighborhood
          ? user_object.address.neighborhood
          : '';
      // user_parsed.address.city = user_object.city.id;
      user_parsed.city = user_object.city;
      user_parsed.state = user_object.state;
    }

    user_parsed.complement_sector = user_object.complement;

    return user_parsed;
  }

  static parseSector(arrays) {
    var arrayspermissions = [];
    if (arrays.length > 0) {
      arrays.map(function(arraysindex, index) {
        switch (arraysindex) {
          case 'Administrador':
            arrayspermissions.push('admin');
            break;
        }
      });
    }
    return arrayspermissions;
  }

  static parseRoles(arrays) {
    var arrayspermissions = [];
    if (arrays.length > 0) {
      arrays.map(function(arraysindex, index) {
        switch (arraysindex.name) {
          case 'admin':
            arrayspermissions.push('Administrador');
            break;
          case 'speaker':
            arrayspermissions.push('Speaker');
            break;
          case 'normal_user':
            arrayspermissions.push('Usuário App');
            break;
          case 'comunication_marketing':
            arrayspermissions.push('Comunicação e Marketing');
            break;
          case 'academy':
            arrayspermissions.push('Academy');
            break;
          case 'personal_department':
            arrayspermissions.push('Dep.Pessoal');
            break;
          case 'guest':
            arrayspermissions.push('Convidado');
            break;
          case 'legal_person':
            arrayspermissions.push('PJ');
            break;
          case 'store':
            arrayspermissions.push('Editor de Loja');
            break;
          case 'manager':
            arrayspermissions.push('Manager');
            break;
          case 'editor':
            arrayspermissions.push('Editor de Usuário');
            break;
        }
      });
    }

    return arrayspermissions;
  }

  static parseUserList(arrays) {
    var arrayusers = {
      pages: 0,
      size: 0,
      users: []
    };
    if (arrays.length > 0) {
      arrays.map(function(user, index) {
        var user_format = {};
        user_format.name = user.name;
        user_format.id = user.id;
        user_format.email = user.email;
        if (user.datebirthday !== null) {
          user_format.birthday = moment
            .tz(user.datebirthday, 'Europe/Berlin')
            .format('DD/MM/YYYY');
        } else {
          user_format.birthday = 'Data não informada';
        }
        if (user.roles[0] !== undefined) {
          switch (user.roles[0].name) {
            case 'admin':
              user_format.roles = 'Administrador';
              break;
            case 'speaker':
              user_format.roles = 'Speaker';
              break;
            case 'normal_user':
              user_format.roles = 'Usuário App';
              break;
            case 'guest':
              user_format.roles = 'Convidado';
              break;
            case 'legal_person':
              user_format.roles = 'PJ';
              break;
            case 'store':
              user_format.roles = 'Editor de Loja';
              break;
            case 'manager':
              user_format.roles = 'Manager';
              break;
            case 'editor':
              user_format.roles = 'Editor de Usuário';
              break;
          }
        }
        user_format.image = user.image.url;
        user_format.score = user.score;
        if (user.sector) {
          user_format.sector = user.sector.name;
        }
        arrayusers.size = user.size[0];
        arrayusers.pages = user.size[1];

        arrayusers.users.push(user_format);
      });
    }
    return arrayusers;
  }

  static testDataCreate() {
    const formUser = new FormData();
    formUser.append('name', 'Victor Lima 1');
    formUser.append('email', '206.239.380-65');
    formUser.append('roles[]', 'normal_user');
    // formUser.append('datebirthday', "17/10/1999");
    formUser.append('score', '0');
    formUser.append('tags[]', '1');
    formUser.append('password', '123');
    formUser.append(
      '[contacts_attributes][][contact]',
      'FulanoSilvaJuatama2@gmail.com'
    );
    formUser.append('[contacts_attributes][][kind]', 'email'); //or phone
    // formUser.append('admitted_at', "26/05/2022");
    formUser.append('sendemail', 'true');
    formUser.append('city_id', '4622'); //suvinil
    formUser.append('state_id', '24'); //suvinil

    return formUser;
  }

  static formDataCreate(values, contacts, academyRole, isEdit = false) {
    const newValues = { ...values };

    const formUser = new FormData();
    if (
      newValues.auto_declared !== null &&
      newValues.auto_declared !== undefined
    ) {
      formUser.append('auto_declared', newValues.auto_declared);
    }

    if (
      newValues.app_discovery !== null &&
      newValues.app_discovery !== undefined
    ) {
      formUser.append('app_discovery_id', newValues.app_discovery);
    }

    if (newValues.cnpj !== null && newValues.cnpj !== undefined) {
      formUser.append('cnpj', newValues.cnpj);
    }

    formUser.append('name', newValues.name_user);
    if (newValues.send_email) {
      formUser.append('sendemail', newValues.send_email);
    }

    if (newValues.tags_user !== undefined) {
      newValues.tags_user.map(function(tag, index) {
        formUser.append('tags[]', tag.value);
      });
    }

    if (!!academyRole) {
      formUser.append('roles[]', 'academy');
    } else if (newValues.permission_userArray) {
      newValues.permission_userArray = newValues.permission_userArray.filter(
        (role) => role !== 'Academy'
      );
    }

    if (newValues.permission_user !== undefined) {
      newValues.permission_user = this.parseRolesToSend(
        newValues.permission_user
      );

      formUser.append('roles[]', newValues.permission_user);
      if (newValues.id === localStorage.getItem('Userid')) {
        if (newValues.permission_user.includes('admin')) {
          localStorage.setItem('ADMIN', true);
        } else {
          localStorage.setItem('ADMIN', false);
        }
      }
    }

    if (newValues.tags_user_speaker !== undefined) {
      newValues.tags_user_speaker.map(function(tag, index) {
        return formUser.append('tagspeaker[]', tag.value);
      });
    }

    function userSex(type) {
      const UserSex = {
        'Mulher cis': 'cis_woman',
        'Mulher trans': 'trans_woman',
        'Homem cis': 'cis_man',
        'Homem trans': 'trans_man',
        'Prefere não se identificar': 'prefers_not_to_identify',
        'Não binário': 'not_binary',
        Outros: 'other',
        default: 'other'
      };
      return UserSex[type] || UserSex.default;
    }

    let sex = userSex(newValues.sex_user);

    if (sex === 'other') {
      formUser.append('sex', sex);
      formUser.append('custom_gender', newValues.custom_gender);
    } else {
      formUser.append('sex', sex);
    }

    if (newValues.birthday_user !== undefined) {
      formUser.append('datebirthday', newValues.birthday_user);
    }

    formUser.append('email', newValues.email_user);

    if (contacts !== undefined && !isEdit) {
      contacts.forEach((contact, k) => {
        if (contact.value !== undefined) {
          formUser.append(`[contacts_attributes][][contact]`, contact.value);
          formUser.append(`[contacts_attributes][][kind]`, contact.kind);
        }
      });
    } else if (contacts !== undefined && isEdit) {
      contacts.forEach((contact, k) => {
        if (contact.value !== undefined) {
          formUser.append(`[contacts_attributes][${k}][id]`, contact.id);
          formUser.append(
            `[contacts_attributes][${k}][contact]`,
            contact.value
          );
          formUser.append(`[contacts_attributes][${k}][kind]`, contact.kind);
        }
      });
    }

    if (newValues.userBrands && newValues.userBrands.length) {
      newValues.userBrands.forEach((brand) => {
        formUser.append('allowed_brands_ids[]', brand.id);
      });
    }

    formUser.append('admitted_at', newValues.admitted_at);

    if (style.teamName.name !== 'Pintou Parceria Suvinil')
      formUser.append('sector_id', newValues.sector_id);

    formUser.append(
      'score',
      newValues.score_user !== undefined
        ? String(newValues.score_user).replaceAll('.', '')
        : 0
    );
    if (newValues.score_user_speaker) {
      formUser.append(
        'scorespeaker',
        String(newValues.score_user_speaker).replaceAll('.', '')
      );
    }
    if (newValues.publications_score) {
      formUser.append('publications_score', newValues.publications_score);
    }
    if (newValues.password_user) {
      formUser.append('password', newValues.password_user);
    }
    if (newValues.password_user_new) {
      formUser.append('password', newValues.password_user_new);
    }
    if (newValues.img !== undefined) {
      formUser.append('image', newValues.img[0]);
    }

    if (hasExternalRegister) {
      if (
        newValues.neighborhood !== undefined ||
        newValues.number !== undefined ||
        newValues.street !== undefined ||
        newValues.zip_code !== undefined ||
        newValues.complement !== undefined
      ) {
        if (newValues.neighborhood !== undefined)
          formUser.append('address[neighborhood]', newValues.neighborhood);

        if (newValues.street !== undefined)
          formUser.append('address[street]', newValues.street);

        if (newValues.number !== undefined)
          formUser.append('address[number]', newValues.number);

        if (newValues.zip_code !== undefined)
          formUser.append('address[zip_code]', newValues.zip_code);

        if (newValues.complement !== undefined)
          formUser.append('address[complement]', newValues.complement);

        formUser.append('address[city]', newValues.cityName);
        formUser.append('address[uf]', newValues.stateName);
      }

      formUser.append('city_id', newValues.city);
      formUser.append('state_id', newValues.state);
    }

    if (
      newValues.complement_sector !== null &&
      newValues.complement_sector !== undefined
    )
      formUser.append('complement', newValues.complement_sector);

    return formUser;
  }

  static parseRolesToSend(roles) {
    let roles_parsed = '';
    switch (roles) {
      case 'Administrador':
        return (roles_parsed = 'admin');
      case 'Speaker':
        return (roles_parsed = 'speaker');
      case 'Usuário App':
        return (roles_parsed = 'normal_user');
      case 'Externo':
        return (roles_parsed = 'external');
      case 'Convidado':
        return (roles_parsed = 'guest');
      case 'PJ':
        return (roles_parsed = 'legal_person');
      case 'Editor de Loja':
        return (roles_parsed = 'store');
      case 'Editor de Conteúdo':
        return (roles_parsed = 'Manager');
      case 'Editor de Usuário':
        return (roles_parsed = 'editor');
    }
  }

  static insertUserToEditScore(user, remove) {
    return (dispatch) => {
      dispatch({
        type: 'INSERT_USER_TO_EDIT_SCORE',
        user: user,
        remove: remove
      });
    };
  }

  static getActivities({ reset }) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      API_V2.get('user_activities', {
        ...Config.headers()
      })
        .then(function(response) {
          dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
          dispatch({
            type: 'GET_ACTIVITIES',
            data: response.data.user_activities,
            reset: reset
          });
        })
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static getAppDiscoveryList() {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      API_V2.get('app_discoveries', {
        ...Config.headers()
      }).then(function(response) {
        const appDicoverylist = response.data.app_discoveries.map(
          ({ id, origin_option }) => ({
            id_sector: id,
            name_sector: origin_option
          })
        );
        teamsAppDiscovery[style.teamName.name] = appDicoverylist;

        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
        dispatch({
          type: 'GET_APP_DISCOVERY',
          data: appDicoverylist
        });
      });
    };
  }

  static getFilterUsers(
    filter_selectd = '',
    value_filter = '',
    name_user = '',
    page = 1,
    order = ''
  ) {
    return (dispatch) => {
      PubSub.publish('clear-list', false);
      switch (filter_selectd) {
        case 'Página 1':
          dispatch(this.getUserList());
          break;

        case 'Mês do Aniversário':
          var mount = this.parseMonth(value_filter);
          dispatch({
            type: 'USER_FILTER_CREATE',
            userFilter: {
              selectedFilter: filter_selectd,
              filterValue: value_filter,
              birthdayMonth: mount,
              nameSearch: name_user
            }
          });
          API.get(
            `users?page=${page}&uid_or_name=${name_user}&month_birthday=${mount}&score=${order}`,
            Config.headers()
          )
            .then(
              function(response) {
                dispatch({
                  type: 'GET_USER_LIST',
                  user_list: this.parseUserList(response.data)
                });
                dispatch({ type: 'GET_PAGE', page });
                dispatch({ type: 'NO_SUBMITTING' });
              }.bind(this)
            )
            .catch(function(err) {
              dispatch({
                type: 'OPEN_ERROR',
                alert: {
                  visible: true,
                  message: errorHandler(err)
                }
              });
            });
          break;

        case 'Setor':
          dispatch({
            type: 'USER_FILTER_CREATE',
            userFilter: {
              selectedFilter: filter_selectd,
              filterValue: value_filter,
              birthdayMonth: '',
              nameSearch: name_user
            }
          });
          API.get(
            `users?page=${page}&uid_or_name=${name_user}&sector=${value_filter}&score=${order}`,
            Config.headers()
          )
            .then(
              function(response) {
                dispatch({
                  type: 'GET_USER_LIST',
                  user_list: this.parseUserList(response.data)
                });
                dispatch({ type: 'GET_PAGE', page });
                dispatch({ type: 'NO_SUBMITTING' });
              }.bind(this)
            )
            .catch(function(err) {
              dispatch({
                type: 'OPEN_ERROR',
                alert: {
                  visible: true,
                  message: errorHandler(err)
                }
              });
            });
          break;

        case 'Gênero':
          let positionValueFilter = [
            'Homem cis',
            'Mulher cis',
            'Não binário',
            'Prefere não se identificar',
            'Outros',
            'Homem trans',
            'Mulher trans'
          ].indexOf(value_filter);
          dispatch({
            type: 'USER_FILTER_CREATE',
            userFilter: {
              selectedFilter: filter_selectd,
              filterValue: positionValueFilter,
              birthdayMonth: '',
              nameSearch: name_user
            }
          });
          API.get(
            `users?page=${page}&sex=${positionValueFilter}&uid_or_name=${name_user}&score=${order}`,
            Config.headers()
          )
            .then(
              function(response) {
                dispatch({
                  type: 'GET_USER_LIST',
                  user_list: this.parseUserList(response.data)
                });
                dispatch({ type: 'GET_PAGE', page });
                dispatch({ type: 'NO_SUBMITTING' });
              }.bind(this)
            )
            .catch(function(err) {
              dispatch({
                type: 'OPEN_ERROR',
                alert: {
                  visible: true,
                  message: errorHandler(err)
                }
              });
            });
          break;

        case 'Tags':
          let tags = '';
          for (var i = 0; i < 10; i++) {
            if (value_filter[i] !== undefined) {
              tags = tags + '&tag[]=' + value_filter[i];
            }
          }
          dispatch({
            type: 'USER_FILTER_CREATE',
            userFilter: {
              selectedFilter: filter_selectd,
              filterValue: value_filter,
              birthdayMonth: '',
              nameSearch: name_user
            }
          });
          API.get(
            `users?page=${page}&uid_or_name=${name_user}${tags}`,
            Config.headers()
          )
            .then(
              function(response) {
                dispatch({
                  type: 'GET_USER_LIST',
                  user_list: this.parseUserList(response.data)
                });
                dispatch({ type: 'GET_PAGE', page });
                dispatch({ type: 'NO_SUBMITTING' });
              }.bind(this)
            )
            .catch(function(err) {
              dispatch({
                type: 'OPEN_ERROR',
                alert: {
                  visible: true,
                  message: errorHandler(err)
                }
              });
            });
          break;

        case 'Data de contratação':
          var date = moment
            .tz(value_filter, 'Europe/Berlin')
            .format('DD/MM/YYYY');
          API.get(
            'users/?filter=newuser&page=1&datecreate=' + date,
            Config.headers()
          )
            .then(
              function(response) {
                dispatch({
                  type: 'GET_USER_LIST',
                  user_list: this.parseUserList(response.data)
                });
                dispatch({ type: 'NO_SUBMITTING' });
              }.bind(this)
            )
            .catch(function(err) {
              dispatch({
                type: 'OPEN_ERROR',
                alert: {
                  visible: true,
                  message: errorHandler(err)
                }
              });
            });
          break;

        case 'Pontuação':
          var score = value_filter.split('-');

          if (score.length === 2) {
            API.get(
              'users/?filter=score&page=1&min=' + score[0] + '&max=' + score[1],
              Config.headers()
            )
              .then(
                function(response) {
                  dispatch({
                    type: 'GET_USER_LIST',
                    user_list: this.parseUserList(response.data)
                  });
                  dispatch({ type: 'NO_SUBMITTING' });
                }.bind(this)
              )
              .catch(function(err) {
                dispatch({
                  type: 'OPEN_ERROR',
                  alert: {
                    visible: true,
                    message: errorHandler(err)
                  }
                });
              });
          }
          break;

        case 'Login':
          const urlValues = {
            'Já realizaram login': 'logged_in',
            'Não realizaram login': 'not_logged'
          };
          dispatch({
            type: 'USER_FILTER_CREATE',
            userFilter: {
              selectedFilter: filter_selectd,
              filterValue: value_filter
            }
          });
          API.get(
            `users?page=${page}&auth_status=${urlValues[value_filter]}`,
            Config.headers()
          )
            .then(
              function(response) {
                dispatch({
                  type: 'GET_USER_LIST',
                  user_list: this.parseUserList(response.data)
                });
                dispatch({ type: 'GET_PAGE', page });
                dispatch({ type: 'NO_SUBMITTING' });
              }.bind(this)
            )
            .catch(function(err) {
              dispatch({
                type: 'OPEN_ERROR',
                alert: {
                  visible: true,
                  message: errorHandler(err)
                }
              });
            });
          break;

        case 'Atividade':
          let positionValueFilter2 = teamsActivities[
            style.teamName.name
          ].indexOf(value_filter);

          const filterValueOptions = {
            Vedacit: value_filter,
            mestreconstrutor: value_filter,
            Ecom: value_filter,
            'Pintou Parceria Suvinil': positionValueFilter2
          };

          dispatch({
            type: 'USER_FILTER_CREATE',
            userFilter: {
              selectedFilter: filter_selectd,
              filterValue: filterValueOptions[style.teamName.name],
              nameSearch: name_user
            }
          });

          API.get(`users`, {
            ...Config.headers(),
            params: {
              page,
              ...(hasExternalActivities && { user_activity_id: value_filter }),
              ...(!hasExternalActivities && {
                auto_declared: positionValueFilter2
              }),
              uid_or_name: name_user,
              score: order
            }
          })
            .then(
              function(response) {
                dispatch({
                  type: 'GET_USER_LIST',
                  user_list: this.parseUserList(response.data)
                });
                dispatch({ type: 'GET_PAGE', page });
                dispatch({ type: 'NO_SUBMITTING' });
              }.bind(this)
            )
            .catch(function(err) {
              dispatch({
                type: 'OPEN_ERROR',
                alert: {
                  visible: true,
                  message: errorHandler(err)
                }
              });
            });
          break;

        case 'Profissões':
          let positionValueFilterSecond = teamsActivities[
            style.teamName.name
          ].indexOf(value_filter);

          const filterValuesOptions = {
            Vedacit: value_filter,
            mestreconstrutor: value_filter,
            'Pintou Parceria Suvinil': positionValueFilterSecond
          };

          dispatch({
            type: 'USER_FILTER_CREATE',
            userFilter: {
              selectedFilter: filter_selectd,
              filterValue: filterValuesOptions[style.teamName.name],
              nameSearch: name_user
            }
          });

          API.get(`users`, {
            ...Config.headers(),
            params: {
              page,
              ...(hasExternalActivities && { user_activity_id: value_filter }),
              ...(!hasExternalActivities && {
                auto_declared: positionValueFilterSecond
              }),
              uid_or_name: name_user,
              score: order
            }
          })
            .then(
              function(response) {
                dispatch({
                  type: 'GET_USER_LIST',
                  user_list: this.parseUserList(response.data)
                });
                dispatch({ type: 'GET_PAGE', page });
                dispatch({ type: 'NO_SUBMITTING' });
              }.bind(this)
            )
            .catch(function(err) {
              dispatch({
                type: 'OPEN_ERROR',
                alert: {
                  visible: true,
                  message: errorHandler(err)
                }
              });
            });
          break;

        default:
          dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
          API.get(
            `users?page=${page}&uid_or_name=${name_user}&score=${order}`,
            Config.headers()
          )
            .then((response) => {
              let user_list = this.parseUserList(response.data);
              dispatch({
                type: 'USER_FILTER_CREATE',
                userFilter: {
                  selectedFilter: filter_selectd,
                  filterValue: value_filter,
                  birthdayMonth: '',
                  nameSearch: name_user
                }
              });
              dispatch({
                type: 'GET_USER_LIST',
                user_list
              });
              dispatch({
                type: 'GET_PAGE',
                page: page
              });
              dispatch({ type: 'NO_SUBMITTING' });
              dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
            })
            .catch(function(err) {
              dispatch({
                type: 'OPEN_ERROR',
                alert: {
                  visible: true,
                  message: errorHandler(err)
                }
              });
            });
          break;
      }
    };
  }

  static parseMonth(values) {
    var month = 0;
    switch (values) {
      case 'Janeiro':
        month = 1;
        break;
      case 'Fevereiro':
        month = 2;
        break;
      case 'Março':
        month = 3;
        break;
      case 'Abril':
        month = 4;
        break;
      case 'Maio':
        month = 5;
        break;
      case 'Junho':
        month = 6;
        break;
      case 'Julho':
        month = 7;
        break;
      case 'Agosto':
        month = 8;
        break;
      case 'Setembro':
        month = 9;
        break;
      case 'Outubro':
        month = 10;
        break;
      case 'Novembro':
        month = 11;
        break;
      case 'Dezembro':
        month = 12;
        break;
    }

    return month;
  }

  static updateUserTags(users, tags) {
    var formData = new FormData();
    if (Array.isArray(users)) {
      users.forEach((user) => {
        formData.append('ids[]', user);
      });
    }
    if (Array.isArray(tags)) {
      tags.forEach((tag) => {
        formData.append('tags[]', tag);
      });
    }

    return (dispatch) => {
      API.patch(
        'users/' + users[0] + '?tags_user=true',
        formData,
        Config.headers()
      )
        .then(function(response) {
          dispatch({ type: 'HIDE_MODAL_INSERT_TAG' });
        })
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static getUserTagsAssociation(tags_) {
    var tags = '';
    if (tags_) {
      for (var i = 0; i < tags_.length; i++) {
        if (tags_[i] !== undefined) {
          tags = tags + '&tags_ids[]=' + tags_[i].id;
        }
      }
    }
    return (dispatch) => {
      API.get('tags/range?' + tags, Config.headers())
        .then(
          function(response) {
            const {
              data: { range: userTagAssociation }
            } = response;
            dispatch({ type: 'USER_TAG_ASSOCIATION_GET', userTagAssociation });
          }.bind(this)
        )
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static parseUserListName(arrays) {
    let arrayUsersNames = [];
    if (arrays.length > 0) {
      arrays.map(function(user, index) {
        let user_format = {};
        user_format.name = user.name;
        user_format.id = user.id;
        arrayUsersNames.push(user_format);
      });
    }
    return arrayUsersNames;
  }

  static token_resend(idUser) {
    return (dispatch) => {
      dispatch({
        type: 'SHOW_TOKEN_RESEND',
        idUser: idUser,
        showModal: true
      });
    };
  }

  static hide_token_resend() {
    return (dispatch) => {
      dispatch({
        type: 'HIDE_CONFIRM_RESEND',
        idUser: 0,
        showModal: false,
        confirmModal: false
      });
    };
  }

  static resend_token_send(idUser) {
    return (dispatch) => {
      API.post(`users/${idUser}/resend_token`, '', Config.headers())
        .then(() => {
          dispatch({
            type: 'SHOW_CONFIRM_RESEND',
            confirmModal: true
          });
        })
        .catch((err) => {
          dispatch(this.hide_token_resend());
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static getCustomFields = () => {
    return async (dispatch) => {
      try {
        dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
        dispatch({ type: 'CUSTOM_FIELDS_QUESTIONS_LOADING_START' });
        const {
          data: { personal_questions: customQuestions }
        } = await API.get('personal_questions', Config.headers());

        dispatch({
          type: 'CUSTOM_FIELDS_QUESTIONS_GET',
          customQuestions
        });
        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
        dispatch({ type: 'CUSTOM_FIELDS_QUESTIONS_LOADING_FINISH' });
      } catch (error) {
        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
        dispatch({ type: 'CUSTOM_FIELDS_QUESTIONS_LOADING_FINISH' });
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      }
    };
  };

  static updateActiveField = (updatedQuestions) => {
    return (dispatch) => {
      dispatch({
        type: 'CUSTOM_FIELDS_QUESTIONS_GET',
        customQuestions: updatedQuestions
      });
    };
  };

  static updateQuestion = (id, title, placeholder) => {
    return async (dispatch) => {
      try {
        dispatch({ type: 'CUSTOM_FIELDS_QUESTIONS_LOADING_START' });

        const formData = new FormData();
        formData.append('title', title);
        formData.append('placeholder', placeholder);

        const { data: updatedQuestion } = await API.patch(
          `personal_questions/${id}`,
          formData,
          Config.headers()
        );

        dispatch({
          type: 'CUSTOM_FIELDS_QUESTION_UPDATE',
          updatedQuestion
        });
        dispatch({ type: 'CUSTOM_FIELDS_QUESTIONS_LOADING_FINISH' });
      } catch (error) {
        dispatch({ type: 'CUSTOM_FIELDS_QUESTIONS_LOADING_FINISH' });
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      }
    };
  };

  static getAvailableBrands = () => {
    return async (dispatch) => {
      try {
        const {
          data: { brands: availableBrands, pagination }
        } = await API.get('brands?per_page=12&page=1', Config.headers());

        dispatch({
          type: 'SET_AVAILABLE_BRANDS',
          teamBrands: {
            availableBrands,
            pagination
          }
        });
      } catch (error) {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      }
    };
  };

  static fetchMoreBrands = (nextPage) => {
    return async (dispatch) => {
      try {
        dispatch({ type: 'LOADING_BRANDS_START' });
        const {
          data: { brands: newBrands, pagination }
        } = await API.get(
          `brands?per_page=12&page=${nextPage}`,
          Config.headers()
        );

        dispatch({
          type: 'FETCH_MORE_BRANDS',
          payload: {
            newBrands,
            pagination
          }
        });
      } catch (error) {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      } finally {
        dispatch({ type: 'LOADING_BRANDS_FINISH' });
      }
    };
  };

  static getLoggedUsersReport = () => {
    return async (dispatch) => {
      try {
        dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

        const { hasBrands } = teamSettings();

        const { data } = await API.get(
          `historical/authentications.xlsx${hasBrands ? '?with_brand=true' : ''}
          `,
          {
            responseType: 'blob',
            headers: Config.headers().headers
          }
        );

        const src = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = src;
        link.setAttribute(
          'download',
          `relatorio-acessos__${moment().format('L')}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (error) {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      } finally {
        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
      }
    };
  };

  static getBirthdayMessage = () => {
    return async (dispatch) => {
      try {
        const {
          data: { birthday_messages }
        } = await API.get('birthday_messages', Config.headers());

        dispatch({
          type: 'BIRTHDAY_MESSAGE_BODY_GET',
          birthdayMessageBody: birthday_messages[0]
        });

        dispatch({
          type: 'BIRTHDAY_MESSAGE_MODAL_SHOW'
        });
      } catch (error) {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      } finally {
      }
    };
  };

  static registerBirthdayMessage = (messageTitle, messageBody) => {
    return async (dispatch) => {
      try {
        dispatch({
          type: 'BIRTHDAY_MESSAGE_UPDATE_START'
        });

        const formData = new FormData();
        formData.append('title', messageTitle);
        formData.append('message', messageBody);

        const { data } = await API.post(
          'birthday_messages',
          formData,
          Config.headers()
        );

        dispatch({
          type: 'BIRTHDAY_MESSAGE_BODY_GET',
          birthdayMessageBody: data
        });
      } catch (error) {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      } finally {
        dispatch({
          type: 'BIRTHDAY_MESSAGE_UPDATE_FINISH'
        });
        dispatch({
          type: 'BIRTHDAY_MESSAGE_MODAL_HIDE'
        });
      }
    };
  };

  static updateBirthdayMessage = (messageId, messageTitle, messageBody) => {
    return async (dispatch) => {
      try {
        dispatch({
          type: 'BIRTHDAY_MESSAGE_UPDATE_START'
        });

        const formData = new FormData();
        formData.append('title', messageTitle);
        formData.append('message', messageBody);

        const { data } = await API.patch(
          `birthday_messages/${messageId}`,
          formData,
          Config.headers()
        );

        dispatch({
          type: 'BIRTHDAY_MESSAGE_BODY_GET',
          birthdayMessageBody: data
        });
      } catch (error) {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      } finally {
        dispatch({
          type: 'BIRTHDAY_MESSAGE_UPDATE_FINISH'
        });
        dispatch({
          type: 'BIRTHDAY_MESSAGE_MODAL_HIDE'
        });
      }
    };
  };

  static getNotLoggedUsersCount = () => {
    return async (dispatch) => {
      try {
        const { data } = await API.get(
          '/users?auth_status=not_logged&page=1',
          Config.headers()
        );

        const usersCount = data.length > 0 ? data[0].size[0] : 0;

        dispatch({
          type: 'UPDATE_USER_COUNT',
          count: usersCount
        });

        dispatch({ type: 'SHOW_RESEND_WELCOME' });
      } catch (error) {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      }
    };
  };

  static resendWelcomeEmails = () => {
    return async (dispatch) => {
      dispatch({ type: 'RESEND_WELCOME_START' });
      try {
        API.post('/users/resend_new_email', '', Config.headersJson());

        dispatch({ type: 'RESEND_WELCOME_SUCCESS' });
      } catch (error) {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      }
    };
  };

  static getStates = () => {
    return async (dispatch) => {
      try {
        const { data } = await API.get('/states', Config.headers());

        const newData = data.map((item) => {
          return {
            id: item.uf,
            name_sector: item.name,
            id_sector: item.id
          };
        });

        dispatch({
          type: 'UPDATE_LIST_STATES',
          states: newData
        });
      } catch (error) {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      }
    };
  };

  static getCities = (idState) => {
    return async (dispatch) => {
      try {
        const { data } = await API.get(
          `/states/${idState}/cities`,
          Config.headers()
        );

        const newData = data.map((item) => {
          return {
            name_sector: item.name,
            id_sector: item.id
          };
        });

        dispatch({
          type: 'UPDATE_LIST_CITIES',
          cities: newData
        });
      } catch (error) {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      }
    };
  };

  static getMoodSurveyFromProfileAPI = () => {
    return async (dispatch) => {
      try {
        const { data } = await Axios.get(
          `https://base-api.prd.appnoz.com.br/api/v2/clients?team=${style.teamName.name}`
          // Config.headers()
        );

        dispatch({
          type: 'SET_MOOD_SURVEY_ACTIVE',
          hasMoodSurveyActive: data.profile.mood_survey
        });

        sessionStorage.setItem('@Noz-hasMoodSurvey', data.profile.mood_survey);
      } catch (error) {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      }
    };
  };
}
